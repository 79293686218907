import React from "react";

export const Header = () => {
  return (
    <header className="py-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <a href="#" aria-label="Home">
              <img src="notes-icon.png" alt="icon" width={36} height={36} />
            </a>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <button className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
              <a href="https://web.flowernotes.app/" target="_blank">
                Log in
              </a>
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};
