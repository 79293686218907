import react from "react";

export const CallToAction = () => {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-16"
    >
      <img
        className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src="background-call-to-action.jpg"
        alt=""
        width={2347}
        height={1244}
      />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Integrate FlowerNotes into your browser
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Download our extension from Google Chrome Web Store
          </p>
          <div className="flex imtes-centar justify-center mt-5">
            <a
              href="https://chrome.google.com/webstore/detail/flower-power-notes/dcejolhgnipdbefpmjpnjedhikplkegl"
              target="_blank"
              aria-label="Home"
            >
              <img
                src="notes-icon-inverted.png"
                alt="icon"
                width={55}
                height={55}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
