export const Hero = () => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-20 text-center">
      <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-9xl text-transparent bg-clip-text bg-gradient-to-r from-sky-700 to-sky-400">
        Your private contact notes
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-xl tracking-tight text-slate-700">
        Manage your LinkedIn contacts like a pro with Flower Notes
      </p>
      <div className="mt-10 flex justify-center gap-x-6">
        <a
          href="https://chrome.google.com/webstore/detail/flower-power-notes/dcejolhgnipdbefpmjpnjedhikplkegl"
          target="_blank"
          className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900"
        >
          Get started
        </a>
      </div>
    </div>
  );
};
