export const Footer = () => {
  return (
    <footer className="bg-slate-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center border-t border-slate-400/10 py-6">
          <p className="flex items-center max-w-7xl px-4 sm:px-6 lg:px-8 text-sm text-slate-500 mt-2 sm:mt-0 text-center">
            Copyright &copy; {new Date().getFullYear()} FlowerNotes. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
